import { makeStyles } from '@material-ui/core/styles';
import theme from 'theme';
import buttonStyles from 'apputil/button-styles';

export default makeStyles({
  ...buttonStyles,
  marinleft: {
    marginLeft: theme.spacing(5),
  },
  paper: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }),
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  benefit: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  headerGreen: {
    marginRight: 'auto !important',
    marginLeft: 'auto !important',
    width: '50%',
    borderBottomWidth: theme.spacing(0.2),
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.secondary.main,
    [theme.breakpoints.down('lg')]: {
      width: '60%',
    },
    [theme.breakpoints.down('md')]: {
      width: '70%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  headerIcon: {
    marginLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  fourTopBotMargin: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },

  messageContainer: {
    marginTop: theme.spacing(-4),
    marginBottom: theme.spacing(2),
  },

  subText: {
    color: 'rgba(255, 255, 255, 0.7)',
  },

  terms: {
    ...theme.typography.subtitle1,
  },
});
