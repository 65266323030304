import React, { useState, useEffect } from 'react';
import { object } from 'prop-types';
import loadable from '@loadable/component';
import { Elements } from '@stripe/react-stripe-js';

import { STRIPE_KEY } from 'gatsby-env-variables';

import {
  Grid,
  Paper,
  Typography,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  Checkbox,
  Divider,
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulbOn, faStars } from '@fortawesome/pro-light-svg-icons';

import useStyles from './styles';

const StartBenefits = loadable(() => import('components/subscription-benefits/start-up'));
const BronzeBenefits = loadable(() => import('components/subscription-benefits/bronze'));
const SilverBenefits = loadable(() => import('components/subscription-benefits/silver'));
const GoldBenefits = loadable(() => import('components/subscription-benefits/gold'));

const StripeCheckout = loadable(() => import('components/stripe-checkout'));

const getBenefits = ({ name }) => {
  if (name && name === 'Start') {
    return <StartBenefits highlights/>;
  }

  if (name && name === 'Bronze') {
    return <BronzeBenefits highlights/>;
  }

  if (name && name === 'Silver') {
    return <SilverBenefits highlights/>;
  }

  return <GoldBenefits highlights/>;
};

const getIcon = ({ name }) => (name === 'Start' ? faLightbulbOn : faStars);

const BuySubscription = ({ model = {} }) => {
  const styles = useStyles();
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    import('@stripe/stripe-js')
      .then((mod) => {
        const { loadStripe } = mod;
        setStripePromise(
          loadStripe(STRIPE_KEY),
        );
      })
      .then(() => setLoading(false));

    // eslint-disable-next-line
  }, []);

  const {
    name = '', cost = '',
    priceId = '', subscription,
  } = model;
  const periodTime = 'per month';

  const subscriptionTerm = `Once Enabled, you will be charged ${cost} ${periodTime}. You are not bound by a contract.
  Subscriptions can be cancelled at anytime. Time paid for on a cancelled subscription will not be refunded.
  Subscriptions can be restarted at anytime.`;

  return (
        <Paper className={styles.paper} elevation={4}>
          <Grid spacing={2}
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
            >

            <Grid item xs={12} className={styles.fourTopBotMargin}>
              <Typography
                variant="h4"
                component="h3"
                color="inherit"
                align="center"
                className={styles.headerGreen}>
                {`${name} subscription`}
                <FontAwesomeIcon icon={getIcon(subscription)} size="lg" className={styles.headerIcon}/>
                <Typography
                  variant="caption"
                  component="div"
                  align="right"
                  className={styles.headerSubText}>
                  {`Purchasing this subscription you agree to be charged ${cost} ${periodTime}`}
                </Typography>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={10} className={styles.benefit}>
            {getBenefits(subscription)}
            </Grid>
            <Grid item xs={12} className={styles.divider}>
             <Divider />
            </Grid>

            <Grid item xs={12} sm={10}>
              <FormControl
                error={error}
                >
              <FormLabel
                htmlFor="activate-loyalty-card"
                className={styles.pickerLabel}>
                {error
                  ? 'Please check this box to buy this subscription'
                  : `${isChecked ? '' : 'Please check this box to continue.'}`}
              </FormLabel>
              <FormControlLabel
               control={
                 <Checkbox
                 id="activate-loyalty-card"
                  color="secondary"
                  checked={isChecked}
                  onChange={() => {
                    setIsChecked(!isChecked);
                    if (error) {
                      setError(false);
                    }
                  }}
                  name="checkedG"
                  />}
               label={`Enable ${name} subscription`}
               />
               <FormHelperText>
                 <span className={styles.terms}>
                    {error ? `Required. ${subscriptionTerm}` : subscriptionTerm}
                 </span>
               </FormHelperText>
               </FormControl>
            </Grid>

           <Grid item xs={12} sm={10} className={styles.divider}>
            <Divider />
           </Grid>

           <Elements stripe={stripePromise}>
             <StripeCheckout
              canSubmit={() => {
                if (!isChecked) {
                  setError(true);
                  return false;
                }
                return true;
              }}
              subscriptionType={name}
              priceId={priceId}
              stripeScriptLoading={loading}
              />
           </Elements>

           <Grid item xs={12} sm={10} className={styles.messageContainer}>
             <Typography
               variant="caption"
               component="p"
               align="right"
               className={styles.subText}>
                 {`Purchasing this subscription you agree to be charged ${cost} ${periodTime}`}
             </Typography>
           </Grid>
          </Grid>
      </Paper>
  );
};

BuySubscription.propTypes = {
  model: object.isRequired,
};

export default BuySubscription;
